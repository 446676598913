const { removeItem, setItem, getItem } = require("./local-storage-service");
const ANALYTICS_CONENT_KEY = "ANALYTICS_ACCEPTED";

function isGAEnabled() {
  return getItem(ANALYTICS_CONENT_KEY) === "true" ? true : false;
}

function disableGA() {
  removeItem(ANALYTICS_CONENT_KEY);
  window.location.reload();
}

function enableGA() {
  setItem(ANALYTICS_CONENT_KEY, "true");
  window.location.reload();
}

function track(eventName, value) {
  const isEnabled = isGAEnabled();
  if (isEnabled === true) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      value: value,
    });
    // return googleAnalyticsClient().track(eventName, payload, options, callback);
  }
}

function initGA() {
  const isEnabled = isGAEnabled();
  if (isEnabled === true) {
    const gtmKey = document.getElementById("gtmKey");
    if (gtmKey && gtmKey.value) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", gtmKey.value);
    }
  }
}

module.exports = {
  enableGA,
  disableGA,
  initGA,
  track,
};
