const { initAll } = require("govuk-frontend");
const { initGA } = require("./analytics-helpers");

window.onscroll = function () {
  // Get the height of the document
  const documentHeight = document.documentElement.scrollHeight;
  // Get the height of the window
  const windowHeight = window.innerHeight;
  // Get the scroll position of the document
  const scrollTop = document.documentElement.scrollTop;
  // Calculate the scroll bottom
  const scrollBottom = documentHeight - windowHeight - scrollTop;
  let mybutton = document.getElementById("back-to-top");
  if (
    document.body.scrollTop > 1900 ||
    document.documentElement.scrollTop > 1900
  ) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
  if (scrollBottom < 190) {
    mybutton.style.position = "absolute";
    mybutton.style.bottom = "250px";
  } else {
    mybutton.style.position = "fixed";
    mybutton.style.bottom = "30px";
  }
};

function app() {
  const backToTopLink = document.getElementById("back-to-top");

  if (backToTopLink) {
    backToTopLink.onclick = function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
  }
}

function onPageLoad() {
  try {
    initAll();
    app();
    initGA()
  } catch (e) {
    // console.log("Error", e);
  } finally {
  }
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", onPageLoad, false);
} else {
  onPageLoad();
}
